/* eslint-disable no-undef */
import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import store from "../store"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/project/:id",
        name: "Portfolio",
        component: () => import("../views/PortfolioItem.vue"),
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  base: process.env.BASE_URL
})

let routeINFOobject
router.beforeEach((to, from) => {
  routeINFOobject = {
    TO: to.name,
    ACTUAL: to.name,
    FROM: from.name,
    portfolio: {
      isPortfolio: (to.name == "Portfolio"),
      portfolioTO_ID: to.params.id,
      portfolioFROM_ID: from.params.id,
      get portfolioTO_INDEX() {return store.getters.projectNavList.indexOf(this.portfolioTO_ID)},
      get portfolioFROM_INDEX() {return store.getters.projectNavList.indexOf(this.portfolioFROM_ID)}
    },
    directions: {
      HOME_ABOUT: (from.name == "Home" && to.name == "About"),
      HOME_PORTFOLIO: (from.name == "Home" && to.name != "About"),
      PORTFOLIO_ABOUT: (from.name == "Portfolio" && to.name == "About"),
      PORTFOLIO_PORTFOLIO: (from.name == "Portfolio" && to.name == "Portfolio"),
      PORTFOLIO_HOME: (from.name == "Portfolio" && to.name == "Home"),
      ABOUT_HOME: (from.name == "About" && to.name == "Home")
    }
  }

  // Cambia el fondo según página
  document.getElementsByTagName("html")[0].style.backgroundColor = (routeINFOobject.TO == "Portfolio") ? `${store.state.database.dinamic.projects[routeINFOobject.portfolio.portfolioTO_ID].color}` : "rgb(242, 242, 242)"
  // Mutations
  store.dispatch("writeRouteINFO", routeINFOobject)
  store.dispatch("writeHoverInfo", false)
})

router.afterEach((to, from) => {
  //Aquí va acciones DESPUES de entrar en la ruta
  window.scroll({left:0, top:1, behavior:"smooth"}) // Scroll to TOP

  //----------------------------------------------------- Esto es para devices y para que se meta en la vista el elemento del menu en el que nos encontramos
  if(document.getElementById("portfolioNavigation")) {
    if((routeINFOobject.FROM == "Home" || routeINFOobject.FROM == "Portfolio") && routeINFOobject.TO == "Portfolio") {
      let portNavWrap = {
        el: document.getElementById("portfolioNavigation"),
        get xPos() {return this.el.getBoundingClientRect().x},
        get width() {return this.el.getBoundingClientRect().width}
      }

      let navAct = {
        el: document.querySelector(`#portfolioNavigation .${routeINFOobject.portfolio.portfolioTO_ID}`),
        get xPos() {return this.el.getBoundingClientRect().x},
        get width() {return this.el.getBoundingClientRect().width},
        get offsetL() {return this.el.offsetLeft},
        get marginL() {return Number(getComputedStyle(this.el).marginLeft.slice(0,-2))}
      }
    
      //AutoScroll si el elemento activo del menú está oculto en el overflow
      if ( (navAct.xPos + navAct.width) > (portNavWrap.xPos + portNavWrap.width) ) {
        portNavWrap.el.scroll({
          top: 0,
          left: (navAct.offsetL + navAct.width + navAct.marginL - portNavWrap.width),
          behavior: "smooth"
        })
      } else if (navAct.xPos < portNavWrap.xPos) {
        portNavWrap.el.scroll({
          top: 0,
          left: (navAct.offsetL - navAct.marginL),
          behavior: "smooth"
        })
      }
    } else if (routeINFOobject.FROM == "Portfolio" && routeINFOobject.TO == "Home") {
      document.getElementById("portfolioNavigation").scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }
  }
})

export default router
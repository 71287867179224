<template>
  <div 
  :class="[$route.name]"
  @mousemove="curInfoPos($event), writeHoverInfo(getHoverInfo($event))"
  >
    <transition name="cursor-widget-trans" mode="out-in" >
      <CursorWidget id="cursorWidget" :style="{top: `${curPosData[0]}px`, left: `${curPosData[1]}px`}" v-if="hoverInfo.data && windowSize == 'desktop' && mobileDevice == 'desktop'"/>
    </transition>
    
    <div id="menuDesktopWrapper" v-if="windowSize == 'desktop' && mobileDevice == 'desktop'">
      <transition name="slide-main-menu" appear mode="out-in">
        <div id="mainMenuWrapper">
          <img id="logo" :src="require('./assets/icons/menu/logo.svg')" alt="Dani Mnez. logo">
          <nav aria-label="desktop" id="mainNavigation">
            <ul class="mainLinks">
              <li>
                <router-link  id="home-button" :to="'/'" >
                  <img class="menu-button" :src="require('./assets/icons/menu/home.svg')" alt="Icono menú home">
                </router-link>
              </li>
              <li>
                <router-link  id="about-button" :to="'/about'" >
                  <img class="menu-button" :src="require('./assets/icons/menu/about.svg')" alt="Icono menú about">
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </transition>
      <transition name="slide-portfolio-navigation" appear >
        <PortfolioNavigation id="portfolioNavigation" v-if="$route.name == 'Portfolio' || $route.name == 'Home'" />    
      </transition>
      <transition name="slide-social" appear mode="out-in">
        <SocialNav id="socialNav" />
      </transition>
    </div>

    <div id="menuMobile" v-if="(mobileDevice != 'desktop') || (mobileDevice == 'desktop' && windowSize != 'desktop')" >
      <div id="logo">
          <img :src="require('./assets/icons/menu/logo.svg')" alt="Dani Mnez. logo">
      </div>
      <nav aria-label="mobile" id="mainNavigation">
        <ul class="mainLinks">
          <li>
            <router-link id="home-button" :to="'/'" >
              <img class="menu-button" :src="require('./assets/icons/menu/home.svg')" alt="Icono menú home">
            </router-link>
          </li>
          <li>
            <router-link id="about-button" :to="'/about'" >
              <img class="menu-button" :src="require('./assets/icons/menu/about.svg')" alt="Icono menú about">
            </router-link>
          </li>
        </ul>
      </nav>
    </div>

    <transition name="portNav-devices" appear mode="out-in">
      <PortfolioNavigation id="portfolioNavigation" @scroll="portfolioNavDegControl()" v-if="($route.name == 'Portfolio' || $route.name == 'Home') && (windowSize != 'desktop' || (windowSize == 'desktop' && mobileDevice != 'desktop'))" />    
    </transition>

    <transition name="slide-social" appear mode="out-in">
      <SocialNav id="socialNav" v-if="windowSize != 'desktop' || (windowSize == 'desktop' && mobileDevice != 'desktop')" />
    </transition>

    <router-view v-slot="{ Component }"  class="content">
      <transition
      type="transition"
      mode="out-in"

      @before-enter="onBeforeEnter"
      @enter="onEnter"

      @before-leave="onBeforeLeave"
      @leave="onLeave"

      :css="false"
      >
        <component :is="Component" v-if="$route.name !='Home'" :key="$route.path"/> <!-- key es el trigger que hace que cada vez que varíe, se cambie de componente y por lo tanto se haga la animación -->
      </transition>
    </router-view>

    <transition name="portfolio-img" mode="out-in" >
      <div v-if="routeINFO.TO == 'Portfolio'" id="bkgndImg" :style="[(routeINFO.TO == 'Portfolio') ? { backgroundImage: 'url('+require(`./assets/portfolio-img/${routeINFO.portfolio.portfolioTO_ID}/${routeINFO.portfolio.portfolioTO_ID}-cover.png`)+')'} : {backgroundImage: 'url('+require(`./assets/portfolio-img/${routeINFO.portfolio.portfolioFROM_ID}/${routeINFO.portfolio.portfolioFROM_ID}-cover.png`)+')'}]"></div>  
    </transition>
  </div>
</template>

<script>
import PortfolioNavigation from "./components/PortfolioNavigation.vue"
import SocialNav from "./components/SocialNav.vue"
import CursorWidget from "./components/CursorInfoWidget.vue"

import { mapGetters, mapActions } from "vuex"

export default {
  name: "app",
  data() {
    return {
      curPosData: 0
    }
  },
  created() {
    let mobileDeviceValue = this.isMobileDevice()
    this.writeMobileDevice(mobileDeviceValue)
    this.sizeOfWindow()

    window.addEventListener("resize", this.resizeListenerWrangle)
  },
  mounted() {
    setTimeout(() => { //Set timeout es un FIX para hacer que funcione
      if (this.$route.name != "About") {
        this.portfolioNavScrollReset()
        this.centerPortfolioNavDevices()
      }
    }, 1)
  },
  updated() {
    if (this.$route.name != "About") {
      this.centerPortfolioNavDevices()
      this.portfolioNavDegControl()
    }
  },
  destroyed() { window.removeEventListener("resize", this.resizeListenerWrangle) },
  components: {
    PortfolioNavigation,
    SocialNav,
    CursorWidget
  },
  methods: {
    ...mapActions([
      "writeHoverInfo",
      "writeMobileDevice",
      "writeWindowSize",
      "writeRouteINFO"
    ]),
    resizeListenerWrangle() { 
      this.sizeOfWindow()
      this.centerPortfolioNavDevices()
      this.portfolioNavDegControl()
    },
    portfolioNavDegControl() {
      if (this.windowSize != "desktop" && (this.routeINFO.ACTUAL == "Portfolio" || this.routeINFO.ACTUAL == "Home")) {
        let windowPadding = Number(getComputedStyle(document.getElementById("app").firstChild).padding.slice(0,-2)),
          webClippingWidth = window.innerWidth - (windowPadding*2),
          portfolioNavWrapper = document.getElementById("portfolioNavigation"),
          
          portfolioNavWrapperScrolled = portfolioNavWrapper.scrollLeft,

          portfolioNavWidth = document.getElementById("portfolioNavigation").firstChild.getBoundingClientRect().width

        //Control de máscaras a los lados si hay overflow
        let overflowStyle = function() {
          if (portfolioNavWidth > webClippingWidth) {
            if (portfolioNavWrapperScrolled == 0) {
              return "white 0% 90%, transparent 100%" //Solo overflow derecha
            } else {
              return ((webClippingWidth + portfolioNavWrapperScrolled) == portfolioNavWidth) ? "transparent 0%, white 10% 100%" : "transparent 0%, white 10% 90%, transparent 100%" //Degradado izquierda / degradado ambos
            }
          } else {
            return "white 0% 100%"
          }
        }
        document.getElementById("portfolioNavigation").style.webkitMaskImage = `linear-gradient(to right, ${overflowStyle()})`
      }
    },
    centerPortfolioNavDevices() {
      if (this.windowSize != "desktop" && this.routeINFO.ACTUAL != "About") {
        let portfolioNavWrapperWidth = document.getElementById("portfolioNavigation").getBoundingClientRect().width,
          portfolioNavWidth = document.getElementById("portfolioNavigation").firstChild.getBoundingClientRect().width

        document.getElementById("portfolioNavigation").style.justifyContent = (portfolioNavWidth <= portfolioNavWrapperWidth) ? "center" : "start"
      }
    },
    portfolioNavScrollReset() {
      if(this.windowSize != "desktop" && this.routeINFO.ACTUAL == "Home") {
        let portfolioNavWrapper = document.getElementById("portfolioNavigation")

        if(portfolioNavWrapper != null) {
          portfolioNavWrapper.scroll({ top: 0, left: 0, behavior: "smooth" })
        }
      }
    },
    curInfoPos( event ) {
      if (this.hoverInfo && event != undefined) {
        let offsetX = 5,
          offsetY = 5,
          returnValue = [0,0],
          element = document.getElementById("cursorWidget"),
          anchoPuntero = 16 //El alto es igual

        if (element) {
          let anchoVentana = window.innerWidth,
            widgetWidth = Number(getComputedStyle(element).width.substr(0, getComputedStyle(element).width.length-2)),
            widgetHeight = Number(getComputedStyle(element).height.substr(0, getComputedStyle(element).height.length-2)),
            finalWidgetX = event.clientX + widgetWidth + offsetX + anchoPuntero
          
          returnValue[0] = (event.clientY - offsetY - widgetHeight)
          returnValue[1] = (anchoVentana < finalWidgetX) ? (event.clientX - offsetX - widgetWidth) : (event.clientX + offsetX + anchoPuntero)

          this.curPosData = returnValue
        }
      }
    },
    getHoverInfo( event ) {
      let valorInfoType = {type: false, data: false},
        srcEl = event.srcElement,
        tagElem = srcEl.nodeName

      if(this.routeINFO.ACTUAL != "About") {
        if (tagElem == "svg" || tagElem == "path") { //Fix para navPortfolioItem
          valorInfoType.type = "prevNextPortItemNav"
          if (tagElem == "path" && (event.path[5].id == "previousProjectButton" || event.path[5].id == "nextProjectButton")) {
            valorInfoType.data = (event.path[5].id == "nextProjectButton") ? this.nextProjectId : this.previousProjectId
          } else if (tagElem == "svg" && (event.path[1].id == "previousProjectButton" || event.path[1].id == "nextProjectButton")) {
            valorInfoType.data = (event.path[1].id == "nextProjectButton") ? this.nextProjectId : this.previousProjectId 
          }
        } else {
          if (srcEl.className.endsWith("Icon")) { //soft-lang icons
            valorInfoType = {type: "softLangIcon", data: srcEl.alt}
          } else if (srcEl.id.endsWith("-link")) { //social links
            valorInfoType = {type: "socialItem", data: srcEl.alt}
          } else if (srcEl.id == "previousProjectButton" || srcEl.id == "nextProjectButton" ) { //navPortfolioItem
            valorInfoType = {type: "prevNextPortItemNav", data: (srcEl.id== "nextProjectButton") ? this.nextProjectId : this.previousProjectId}
          } else if (srcEl.classList.contains("portfolioItemLink")) { //PortfolioNav
            if (srcEl.classList[1] != "router-link-exact-active" || srcEl.classList[0] != "router-link-active") {
              valorInfoType = {type: "portfolioNavItem", data: this.hoveredProjectKey}
            }
          }
        }
      } else {
        if (srcEl.id.endsWith("-link")) { //social links
          valorInfoType = {type: "socialItem", data: srcEl.alt}
        } 
      }

      return valorInfoType
    },
    sizeOfWindow () { 
      let anchoWeb = window.innerWidth,
        actualWindowSize

      if(anchoWeb >= 1025) {
        actualWindowSize = "desktop"
      } else if (anchoWeb < 1025 && anchoWeb > 660) {
        actualWindowSize = "tablet"
      } else {
        actualWindowSize = "mobile"
      }
      this.writeWindowSize(actualWindowSize)
    },
    isMobileDevice() { 
      const deviceInfo = navigator.userAgent,
        deviceListArray = ["Android", "webOS", "iPhone", "iPad", "iPod", "BlackBerry", "IEMobile", "Opera Mini"]
      
      let result
      for (let i = 0; i < deviceListArray.length-1; i++) {
        if ((deviceInfo.includes(deviceListArray[i]))) {
          result = deviceListArray[i]
          break
        } else {
          result = "desktop"
        }
      }
      return result 
    },
    // ENTERING TRANSITIONS
    onBeforeEnter( el ) {
      el.style.transition = "transform .35s cubic-bezier(0.25, 1, 0.5, 1), opacity .35s cubic-bezier(0.25, 1, 0.5, 1)"
      el.style.opacity = 0

      if (this.routeINFO.directions.HOME_ABOUT || this.routeINFO.directions.HOME_PORTFOLIO || this.routeINFO.directions.PORTFOLIO_ABOUT) { //NO todas las combinaciones son necesarias, SOLO las que hacen APARECER elementos
        el.style.transform = "perspective(1000px) translateY(10px)"
      }

      if (this.routeINFO.directions.PORTFOLIO_PORTFOLIO) { //Entre items de PORTFOLIO
        el.style.transform = (this.routeINFO.portfolio.portfolioTO_INDEX > this.routeINFO.portfolio.portfolioFROM_INDEX) ? "perspective(1000px) rotateY(-1deg) translateX(-10px)" : "perspective(1000px) rotateY(1deg) translateX(10px)"
      }
    },
    onEnter( el, done ) {
      setTimeout(() => {
        el.style.opacity = 1

        if (this.routeINFO.directions.HOME_ABOUT || this.routeINFO.directions.HOME_PORTFOLIO || this.routeINFO.directions.PORTFOLIO_ABOUT) { //NO todas las combinaciones son necesarias, SOLO las que hacen APARECER elementos
          el.style.transform = "perspective(1000px) rotateX(0) translateY(0)"
        }
  
        if (this.routeINFO.directions.PORTFOLIO_PORTFOLIO) { //Entre items de PORTFOLIO
          el.style.transform = "perspective(1000px) rotateY(0) translateX(0)"
        }
        done()
      }, 0)
    },
    // LEAVING TRANSITIONS
    onBeforeLeave( el ) {
      el.style.transition = "transform .35s cubic-bezier(0.25, 1, 0.5, 1), opacity .35s cubic-bezier(0.25, 1, 0.5, 1)"
      el.style.opacity = 1

      if(this.routeINFO.directions.PORTFOLIO_HOME || this.routeINFO.directions.PORTFOLIO_ABOUT || this.routeINFO.directions.ABOUT_HOME) { //SOLO de los elementos que DESAPARECEN
        el.style.transform = "perspective(1000px) translateY(0)" 
      }
      if (this.routeINFO.directions.PORTFOLIO_PORTFOLIO) {
        el.style.transform = "perspective(1000px) rotateY(0) translateX(0)"
      }
    },
    onLeave( el, done ) {
      setTimeout(() => {
        if (this.routeINFO.directions.ABOUT_HOME || this.routeINFO.directions.PORTFOLIO_HOME || this.routeINFO.directions.PORTFOLIO_ABOUT) { //SOLO de los elementos que DESAPARECEN
          el.style.transform = "perspective(1000px) translateY(-10px)"
        }
  
        if (this.routeINFO.directions.PORTFOLIO_PORTFOLIO) { //Entre items de PORTFOLIO
          el.style.transform = (this.routeINFO.portfolio.portfolioTO_INDEX < this.routeINFO.portfolio.portfolioFROM_INDEX) ? "perspective(1000px) rotateY(-1deg) translateX(-10px)" : "perspective(1000px) rotateY(1deg) translateX(10px)"
        }
        el.style.opacity = 0
        done()
      }, 0)
    }
  },
  computed: {
    ...mapGetters([
      "database",
      "methods",

      "windowSize",
      "mobileDevice",

      "routeINFO",
      
      "hoverInfo",
      "hoveredProjectKey",

      "nextProjectId",
      "previousProjectId",
      "projectNavList"
    ])
  }
}
</script>

<style lang="scss">
  @import "../styles/styles.scss";

  .slide-main-menu { //TRANSICIÓN PRIMERA ENTRADA WEB - MENU PPAL.
    &-enter-active { transition: transform .5s ease-in-out, opacity .3s ease-in-out }

    &-enter-from { 
      transform: perspective(100px) rotateX(5deg) translateY(-20px);
      opacity: 0;
    }

    &-enter-to { 
      transform: perspective(100px) rotateX(0) translateY(0);
      opacity: 1;
    }
  }

  .slide-portfolio-navigation { //TRANSICIONES PORTFOLIO MENU
      &-enter-active, &-leave-active  { transition: transform .5s ease-in-out, opacity .3s ease-in-out }

      &-enter-from, &-leave-to {
        transform: perspective(100px) rotateY(-2deg) translateX(-20px);
        opacity: 0;
      }

      &-enter-to, &-leave-from { 
        transform: perspective(100px) rotateY(0) translateX(0);
        opacity: 1;
      }
  }

  .slide-social { //TRANSICIÓN PRIMERA ENTRADA WEB - MENU SOCIAL.
    &-enter-active { transition: transform .5s ease-in-out, opacity .3s ease-in-out }

    &-enter-from { 
      transform: perspective(100px) rotateX(-5deg) translateY(20px);
      opacity: 0;
    }
    &-leave-to { 
      transform: perspective(100px) rotateX(0) translateY(0);
      opacity: 1;
    }
  }

  .cursor-widget-trans {
    &-enter-active, &-leave-active { transition: transform .1s ease-in-out, opacity .1s ease-in-out }

    &-enter-from {       
      transform: perspective(100px) rotateX(-3deg) translateY(10px);
      opacity: 0; 
    }
    &-leave-to {       
      transform: perspective(100px) rotateX(3deg) translateY(-10px);
      opacity: 0; 
    }
    &-enter-to, &-leave-from {      
      transform: perspective(100px) rotateX(0) translateY(0);
      opacity: 1; 
    }
  }

  .portfolio-img {
    &-enter-active, &-leave-active { transition: opacity .3s ease-in-out }

    &-enter-from, &-leave-to{       
      opacity: 0; 
    }
    &-enter-to, &-leave-from {      
      opacity: 1; 
    }
  }
</style>
import { createStore } from "vuex"
import json from "../../db.json"
import router from "../router"

export default createStore({
  state: {
    database: json,

    windowSize: false,
    mobileDevice: false,

    routeINFO: {
      TO: false,
      FROM: false,
      portfolio: {
        isPortfolio: false,
        portfolioTO_ID: false,
        portfolioFROM_ID: false
      },
      directions: {
        HOME_ABOUT: false,
        HOME_PORTFOLIO: false,
        PORTFOLIO_ABOUT: false,
        PORTFOLIO_PORTFOLIO: false,
        PORTFOLIO_HOME : false,
        ABOUT_HOME: false
      }
    },

    hoverInfo: false,
    
    hoveredProjectKey: "",
    projectNavList: Object.keys(json.dinamic.projects)
  },
  getters: {
    database: state => state.database,

    windowSize: state => state.windowSize,
    mobileDevice: state => state. mobileDevice,
    
    hoverInfo: state => state.hoverInfo,

    routeINFO: state => state.routeINFO,
    
    hoveredProjectKey: state => state.hoveredProjectKey,
    actualProjectUrl: state => `url(${state.database.static.backend.imgbaseurl + state.actualProjectKey}.png)`, //Cuando se usa esta manera, tarda mucho en mostrarse la imagen IDK
    projectNavList: state => state.projectNavList,
    nextProjectId: (state, getters) => {
      let result
      if(getters.actualOpenedProjectId == undefined || getters.positionActualProject == state.projectNavList.length-1) {
        result = false
      } else {
        let nextProjectIndex = (getters.positionActualProject < state.projectNavList.length) ? getters.positionActualProject + 1 : state.projectNavList.length-1
        result = getters.projectNavList[nextProjectIndex]
      }
      return result
    },
    actualOpenedProjectId: () => {
      return router.currentRoute.value.params.id
    },
    previousProjectId: (state, getters) => {
      let result
      if(getters.actualOpenedProjectId == undefined || getters.positionActualProject == 0) {
        result = false
      } else {
        let prevProjectIndex = (getters.positionActualProject > 0) ? getters.positionActualProject - 1 : 0
        result = getters.projectNavList[prevProjectIndex]
      }
      return result
    },
    positionActualProject: () => {
      let actualRoute = router.currentRoute.value.params.id
      return (router.currentRoute.value.name == "Home" || router.currentRoute.value.name == "About") ? false : Object.keys(json.dinamic.projects).indexOf(actualRoute)
    }
  },
  mutations: {
    writeHoveredProjectKey ( state, key ) {
      state.hoveredProjectKey = key
    },
    writeHoverInfo( state, key ) {
      state.hoverInfo = key
    },
    writeMobileDevice( state, key ) {
      state.mobileDevice = key
    },
    writeWindowSize( state, key ) {
      state.windowSize = key
    },
    writeRouteINFO( state, key ) {
      state.routeINFO = key
    },
  },
  actions: {
    writeHoveredProjectKey ( context, key ) {
      context.commit("writeHoveredProjectKey", key)
    },
    writeHoverInfo( context, key ) {
      context.commit("writeHoverInfo", key)
    },
    writeWindowSize( context, key ) {
      context.commit("writeWindowSize", key)
    },
    writeMobileDevice( context, key ) {
      context.commit("writeMobileDevice", key)
    },
    notHoveredProject( context ) {
      context.commit("notHoveredProject")
    },
    writeRouteINFO( context, key ) {
      context.commit("writeRouteINFO", key)
    }
  }
})

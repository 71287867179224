/* eslint-disable no-undef */
<template>
  <div id="portfolioNavWrapper">
    <nav>
      <router-link
      v-for='(value, key) of database.dinamic.projects'
      :key="key"
      :to="{name: 'Portfolio', params: {id: key}}"
      :class="[key, 'portfolioItemLink']"
      @mousemove="cardTilt3D( $event )"
      @mouseenter="mouseEnterHandler( key )"
      @mouseleave="mouseLeaveHandler( $event )"
      @click="clickHandler( key, $event )"
      :style="{ backgroundImage: 'url('+ require(`../assets/portfolio-img/${key}/${key}-cover.png`)+')', backgroundColor: `${database.dinamic.projects[key].color}`, borderColor: `${database.dinamic.projects[key].contrastColor}` }"
      >
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
let count=0
export default {
  name: "PortfolioNavigation",
  data() {
    return {}
  },
  methods: {
    ...mapActions([
      "writeHoveredProjectKey"
    ]),
    cardTilt3D( event ) {
      if(this.windowSize == "desktop" && this.mobileDevice == "desktop"){
        let menuPortfolio = document.getElementById("portfolioNavigation"),
          widthMenuPortfolio = 150,
          heightMenuPortfolio = getComputedStyle(menuPortfolio).height.substr(0,getComputedStyle(menuPortfolio).height.length-2),
          elemOrigen = event.target,
          rangoGradosTransformX = 20,
          rangoGradosTransformY = 40

        if (elemOrigen.classList[1] != "router-link-exact-active") {
          let offLeftElem =  elemOrigen.offsetLeft,
            offTopElem =  elemOrigen.offsetTop,
            wElem = 130,
            hElem = 45,
            perspOriginPortfolioNav = `${((offLeftElem + wElem/2)/widthMenuPortfolio)*100}% ${((offTopElem + hElem/2)/heightMenuPortfolio)*100}%`,
            percDisplaceLinkX = ((event.offsetX - wElem/2) / wElem) * 2,
            percDisplaceLinkY = ((event.offsetY - hElem/2) / hElem) * 2
  
          menuPortfolio.style.perspectiveOrigin = perspOriginPortfolioNav
          elemOrigen.style.transform = `rotateY(${percDisplaceLinkX*rangoGradosTransformX}deg) rotateX(${percDisplaceLinkY*(-rangoGradosTransformY)}deg)`
        } 
      }
    },
    resetCardTransforms( event ) {
      if(this.windowSize == "desktop" && this.mobileDevice == "desktop"){ event.target.style.transform = "rotateX(0deg) rotateY(0deg) translateZ(0)" }
    },
    clickHandler( key, event ) {
      this.resetCardTransforms( event )
    },
    mouseEnterHandler( key ) {
      this.writeHoveredProjectKey( key )
    },
    mouseLeaveHandler( event ) {
      this.writeHoveredProjectKey( false )
      this.resetCardTransforms( event )
    }
  },
  computed: {
    ...mapGetters([
      "database",
      "actualOpenedProjectId",
      "windowSize",
      "mobileDevice"
    ])
  }
}
</script>
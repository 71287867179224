<template>
  <nav>
    <ul id="social">
      <li>
        <a target="_blank" href="mailto:danmarpa@gmail.com?Subject=Hablemos" id="mailBttn">¿hablamos?</a>
      </li>
      <li>
        <a target="_blank" :href="database.static.rrss.instagram.url">
          <img :src="require('../assets/icons/social/instagram.svg')" id="Instagram-link" alt="Instagram">
        </a>
      </li>
      <li>
        <a target="_blank" :href="database.static.rrss.linkedin.url">
          <img :src="require('../assets/icons/social/linkedin.svg')" id="LinkedIn-link" alt="LinkedIn">
        </a>
      </li>
      <li>
        <a target="_blank" :href="database.static.rrss.codepen.url">
          <img :src="require('../assets/icons/social/codepen.svg')" id="Codepen-link" alt="Codepen">
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "SocialNav",
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      "database",
      "actualOpenedProjectId"
    ])
  },
  methods: {
    ...mapActions([
    ])
  }
}
</script>
<template>
    <div 
    class="infoWidgetWrapper"
    :style="{color: colorFontWrap, backgroundColor: bgColorWrap, border: `2px solid ${colorFontWrap}`}"
    >
      <p class="infoWidgetText" v-if="infoWidgetElem"> {{infoWidgetElem.text}} </p>
      
      <div class="projectPreviewWrapper" v-if="projectPreviewInfo">
        <p :style="{borderBottom: `1px solid ${projectPreviewInfo.fontColor}`}" class="title">{{ projectPreviewInfo.title }}</p>
        <p :style="{borderRight: `1px solid ${projectPreviewInfo.fontColor}`}" class="client">{{ projectPreviewInfo.client }}</p> 
        <p class="year">{{ projectPreviewInfo.year }}</p>
        <p :style="{borderTop: `1px solid ${projectPreviewInfo.fontColor}`}" class="descript">{{ projectPreviewInfo.desc }}</p>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CursorWidget",
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      "database",
      "hoverInfo"
    ]),
    infoWidgetElem() {
      let infoVariable = false,
        resultado = false
      
      if (this.hoverInfo.type == "socialItem" || this.hoverInfo.type == "softLangIcon") { infoVariable = this.hoverInfo.data }

      if (infoVariable) {
        resultado = {
          text: infoVariable, 
          fontColor: "black", 
          bgColor: "white"
        }
      }

      return resultado
    },
    projectPreviewInfo() {
      let dbProjects = this.database.dinamic.projects,
        infoVariable = false,
        resultado = false
      
      if(this.hoverInfo.type == "prevNextPortItemNav" || this.hoverInfo.type == "portfolioNavItem") {
        infoVariable = this.hoverInfo.data
      }

      if (infoVariable) {
        resultado = {
          title: dbProjects[infoVariable].title, 
          client: dbProjects[infoVariable].client, 
          year: dbProjects[infoVariable].year, 
          desc: dbProjects[infoVariable].shortDescription,
          fontColor: dbProjects[infoVariable].contrastColor,
          bgColor: dbProjects[infoVariable].color
        }
      }

      return resultado
    },
    colorFontWrap() { return (this.infoWidgetElem.text) ? this.infoWidgetElem.fontColor : this.projectPreviewInfo.fontColor }, 
    bgColorWrap() { return (this.infoWidgetElem.text) ? this.infoWidgetElem.bgColor : this.projectPreviewInfo.bgColor }
  }
}
</script>